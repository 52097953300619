import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar/Navbar";
import Autosuggest from "react-autosuggest";
import handleFile from "../../data/fileHandler";
import Calendar from "react-calendar";
import "../../components/Calendar/customCalendar.css";
import Modal from "../Modal/Modal";
import "../common.css";

const Music = () => {
  const [autocomp, setAuto] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [lives, setLives] = useState(2);
  const [lose, setLose] = useState("");
  const [lbl1, correctlbl1] = useState("1");
  const [lbl2, correctlbl2] = useState("2");
  const [lbl3, correctlbl3] = useState("3");
  const [lbl4, correctlbl4] = useState("4");
  const [lbl5, correctlbl5] = useState("5");
  const [lbl6, correctlbl6] = useState("6");
  const [lbl7, correctlbl7] = useState("7");
  const [lbl8, correctlbl8] = useState("8");
  const [lbl9, correctlbl9] = useState("9");
  const [lbl10, correctlbl10] = useState("10");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showRevealAll, setShowRevealAll] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(new Date());
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [showStreakAlert, setStreakAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [streakCounter, setStreakCounter] = useState("0");
  const [givenCorrectAnswers, setGivenCorrectAnswers] = useState(new Set());
  const [originalDate, setOriginalDate] = useState(new Date());
  const [lastSolvedDate, setLastSolvedDate] = useState(null);
  const [solved, setSolved] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const category = "Müzik";

  const fetchData = async (date) => {
    localStorage.setItem("isChangeable", "true");
    setError(null);
    setLoading(true);

    const formattedDate = formatDate(date);
    const postData = {
      category: "Muzik",
      date: formattedDate,
    };

    try {
      const response = await axios.post(
        "https://kuiz.fun/api/index.php",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const val = response.data;
      const newVal1 = val.substring(0, val.length - 3);
      const newVal = newVal1.substring(1);

      setResponseData(JSON.parse(newVal));
      setAuto(JSON.parse(newVal)["autocomplete_list"]);
      setLoading(false);
    } catch (error) {
      setError(`Bugüne ait soru bulunamadı`);
      setResponseData(null);
      const element2 = document.getElementById("inputs");
      if (element2) {
        element2.style.display = "flex";
      }
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const day = `${date.getDate()}`.padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  useEffect(() => {
    const weekAgo = new Date();
    weekAgo.setDate(weekAgo.getDate() - 7);
    setMinDate(weekAgo);

    fetchData(selectedDate);
  }, []);

  const fetchPreviousDayQuestion = () => {
    const prevDay = new Date(selectedDate);
    prevDay.setDate(selectedDate.getDate() - 1);

    if (lastSolvedDate && prevDay <= lastSolvedDate) {
      console.log("Dünün sorusunu zaten çözdünüz.");
      return;
    }

    setSelectedDate(prevDay);
    setValue("");
    setLose("");
    correctlbl1("1");
    correctlbl2("2");
    correctlbl3("3");
    correctlbl4("4");
    correctlbl5("5");
    correctlbl6("6");
    correctlbl7("7");
    correctlbl8("8");
    correctlbl9("9");
    correctlbl10("10");

    setCorrectAnswersCount(0);
    setGivenCorrectAnswers(new Set());
    resetFlashEffect();
    setLives(2);
    setShowRevealAll(false);
    setSolved(false);
    fetchData(prevDay);

    const element2 = document.getElementById("inputs");
    if (element2) {
      element2.style.display = "flex";
    }
    setLastSolvedDate(new Date());
  };

  const handleDateChange = (date) => {
    const element2 = document.getElementById("inputs");
    if (element2) {
      element2.style.display = "flex";
    }
    setShowRevealAll(false);
    setLose(false);
    setOriginalDate(selectedDate);
    setSelectedDate(date);
    setShowCalendar(false);

    if (!changeableDate()) {
      return;
    }

    correctlbl1("1");
    correctlbl2("2");
    correctlbl3("3");
    correctlbl4("4");
    correctlbl5("5");
    correctlbl6("6");
    correctlbl7("7");
    correctlbl8("8");
    correctlbl9("9");
    correctlbl10("10");

    setCorrectAnswersCount(0);
    setGivenCorrectAnswers(new Set());
    resetFlashEffect();
    setLives(2);
    fetchData(date);
  };

  const changeableDate = () => {
    const handleChange = localStorage.getItem("isChangeable");
    if (handleChange === "false") {
      const confirmCancel = window.confirm(
        "Kuiz bitmeden ayrılmak istediğinize emin misiniz? Cevaplarınız kaybolacaktır."
      );

      if (!confirmCancel) {
        setSelectedDate(originalDate);
        setShowCalendar(false);
      }

      return confirmCancel;
    }

    return true;
  };

  const toggleHelp = () => {
    setShowHelp((prev) => !prev);
  };

  const resetFlashEffect = () => {
    for (let i = 1; i <= 10; i++) {
      const element = document.getElementById(`${i}`);
      if (element) {
        element.style.background =
          "linear-gradient(90deg, #e6fdcc 0%, #e0fdff 100%)";
        element.style.border = "none";
      }
    }
  };

  const handleLivesChange = () => {
    if (lives === 1) {
      showAlertWithTimeout("Yalnızca 1 hakkınız kaldı!");
    } else if (lives === 0) {
      setLose("Kaybettiniz...");
      const element = document.getElementById("lose");
      const element2 = document.getElementById("inputs");
      if (element) {
        element.animate(
          [
            { transform: "translateY(10px)" },
            { transform: "translateY(-20px)" },
            { transform: "translateY(10px)" },
          ],
          {
            duration: 1000,
            iterations: Infinity,
          }
        );
      }
      if (element2) {
        element2.style.display = "none";
      }
      setShowRevealAll(true);
      setShowModal(true);
    }
  };

  const showAlertWithTimeout = (message, timeout = 2000) => {
    setAlertMessage(message);
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, timeout);
  };

  const addLives = () => {
    setLives(2);

    const element2 = document.getElementById("inputs");
    element2.style.display = "flex";
    setLose("");
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    var myArray = handleFile(autocomp);

    myArray = myArray["data"].split(",");

    return inputLength === 0
      ? []
      : myArray.filter((music) => music.toLowerCase().includes(inputValue));
  };

  const checkAnswer = (ans) => {
    if (givenCorrectAnswers.has(ans)) {
      return;
    }

    let isCorrect = false;
    localStorage.setItem("isChangeable", "false");

    switch (ans) {
      case responseData["answer1"]:
        correctlbl1(responseData["answer1"]);
        flashGreen("1");
        isCorrect = true;
        break;
      case responseData["answer2"]:
        correctlbl2(responseData["answer2"]);
        flashGreen("2");
        isCorrect = true;
        break;
      case responseData["answer3"]:
        correctlbl3(responseData["answer3"]);
        flashGreen("3");
        isCorrect = true;
        break;
      case responseData["answer4"]:
        correctlbl4(responseData["answer4"]);
        flashGreen("4");
        isCorrect = true;
        break;
      case responseData["answer5"]:
        correctlbl5(responseData["answer5"]);
        flashGreen("5");
        isCorrect = true;
        break;
      case responseData["answer6"]:
        correctlbl6(responseData["answer6"]);
        flashGreen("6");
        isCorrect = true;
        break;
      case responseData["answer7"]:
        correctlbl7(responseData["answer7"]);
        flashGreen("7");
        isCorrect = true;
        break;
      case responseData["answer8"]:
        correctlbl8(responseData["answer8"]);
        flashGreen("8");
        isCorrect = true;
        break;
      case responseData["answer9"]:
        correctlbl9(responseData["answer9"]);
        flashGreen("9");
        isCorrect = true;
        break;
      case responseData["answer10"]:
        correctlbl10(responseData["answer10"]);
        flashGreen("10");
        isCorrect = true;
        break;
      default:
        if (ans.trim() !== "") {
          setLives((prevLives) => prevLives - 1);
          console.log(lives);
          for (let i = 1; i <= 10; i++) {
            document.getElementById(`${i}`).style.background = "#ff0000";
          }
          setTimeout(() => {
            for (let i = 1; i <= 10; i++) {
              document.getElementById(`${i}`).style.background =
                "linear-gradient(90deg, #e6fdcc 0%, #e0fdff 100%)";
            }
          }, 1000);
          handleLivesChange();
        }
    }

    if (isCorrect) {
      const updatedCorrectAnswers = new Set(givenCorrectAnswers).add(ans);
      setGivenCorrectAnswers(updatedCorrectAnswers);

      if (updatedCorrectAnswers.size === 10) {
        const element2 = document.getElementById("inputs");
    if (element2) {
      element2.style.display = "none";
    }
        setShowModal(true);
        handleWin();
      }
    }
    setValue("");
  };

  const handleWin = () => {
    let currentStreak = parseInt(localStorage.getItem("musicStreak")) || 0;
    currentStreak += 1;
    localStorage.setItem("musicStreak", currentStreak.toString());
    localStorage.setItem("isChangeable", "true");
    setStreakCounter(currentStreak);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const inputProps = {
    placeholder: "Cevabınızı giriniz",
    value,
    onChange: (_, { newValue }) => setValue(newValue),
  };

  const handleRevealAll = () => {
    setShowRevealAll(false);
    const element2 = document.getElementById("inputs");
    if (element2) {
      element2.style.display = "none";
    }
    setTimeout(() => {
      correctlbl1(responseData["answer1"]);
      correctlbl2(responseData["answer2"]);
      correctlbl3(responseData["answer3"]);
      correctlbl4(responseData["answer4"]);
      correctlbl5(responseData["answer5"]);
      correctlbl6(responseData["answer6"]);
      correctlbl7(responseData["answer7"]);
      correctlbl8(responseData["answer8"]);
      correctlbl9(responseData["answer9"]);
      correctlbl10(responseData["answer10"]);
    }, 5000);
    setLose(false);
  };

  const flashGreen = (lbl_id) => {
    setCorrectAnswersCount(correctAnswersCount + 1);
    document.getElementById(`${lbl_id}`).style.background = "#228B22";

    setTimeout(() => {
      document.getElementById(`${lbl_id}`).style.background =
        "linear-gradient(90deg, #e6fdcc 0%, #e0fdff 100%)";

      setTimeout(() => {
        document.getElementById(`${lbl_id}`).style.background = "#228B22";

        setTimeout(() => {
          document.getElementById(`${lbl_id}`).style.background =
            "linear-gradient(90deg, #e6fdcc 0%, #e0fdff 100%)";
        }, 500);
      }, 500);
    }, 500);
    document.getElementById(`${lbl_id}`).style.border = "solid #228B22";
  };

  return (
    <>
      <Navbar />
      <section>
        <div className="category-container">
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {responseData && (
            <div>
              <h2 id="lose">{lose}</h2>
              <h2>{responseData["question"]}</h2>
            </div>
          )}
          <div className="circle-container">
            <div className="circle-button" onClick={toggleCalendar}>
              <p>Geçmiş soruları çözmek için tıkla!</p>
            </div>
            <div className="question-mark-button" onClick={toggleHelp}>
              ?
            </div>
            {showHelp && (
              <div className="help-modal">
                <div className="help-content">
                  <h2>Nasıl Oynanır?</h2>
                  <ul className="help-list">
                    <li>Soruların 10 farklı cevabı bulunmaktadır.</li>
                    <li>Cevabınız doğruysa doğru şık yeşil yanacaktır.</li>
                    <li>Cevabınız yanlışsa tüm cevaplar kırmızı yanacaktır.</li>
                    <li>Tüm cevapları bulmak için 3 hakkınız bulunmaktadır.</li>
                    <li>
                      Her yanlış cevap, bir hakkınızın gitmesine sebep olur.
                    </li>
                  </ul>
                  <p className="help-p">İyi eğlenceler!</p>
                  <button className="close-btn" onClick={toggleHelp}>
                    Kapat
                  </button>
                </div>
              </div>
            )}
          </div>
          {showCalendar && (
            <div className="calendar-container">
              <Calendar
                className="calendar"
                onChange={handleDateChange}
                value={selectedDate}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
          )}
          <div className="lives-container">
            {Array.from({ length: lives + 1 }, (_, i) => (
              <p key={i} className={i === lives ? "heart-animation-alert" : ""}>
                ❤️
              </p>
            ))}
          </div>
          <div className="quiz-progress-bar-container">
            <div
              className="quiz-progress-bar"
              style={{
                "--progress-width": `${(correctAnswersCount / 10) * 100}%`,
              }}
            >
              {correctAnswersCount}/10
            </div>
          </div>
          <div className="quiz-answers">
            {[lbl1, lbl2, lbl3, lbl4, lbl5, lbl6, lbl7, lbl8, lbl9, lbl10].map(
              (lbl, index) => (
                <div className="quiz-box" key={index + 1} id={`${index + 1}`}>
                  {lbl}
                </div>
              )
            )}
            <div className="quiz-input" id="inputs">
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={({ value }) =>
                  setSuggestions(getSuggestions(value))
                }
                onSuggestionsClearRequested={() => setSuggestions([])}
                getSuggestionValue={(suggestion) => suggestion}
                renderSuggestion={(suggestion, { isHighlighted }) => (
                  <div
                    className={`autosuggest-suggestion ${
                      isHighlighted ? "focused" : ""
                    }`}
                  >
                    {suggestion}
                  </div>
                )}
                onSuggestionSelected={(event, { suggestion }) => {
                  checkAnswer(suggestion);
                  setValue("");
                }}
                inputProps={inputProps}
                renderSuggestionsContainer={({ containerProps, children }) => (
                  <div {...containerProps} className="autosuggest-container">
                    <div className="autosuggest-suggestions-container">
                      {children}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
          {showRevealAll && (
            <button className="reveal-all-btn" onClick={handleRevealAll}>
              Tüm cevapları görmek için tıkla
            </button>
          )}
        </div>
      </section>
      {showAlert && (
        <div className="alert-container">
          <div className="alert">
            <p>{alertMessage}</p>
          </div>
        </div>
      )}
      {showStreakAlert && (
        <div
          className={`streak-alert-container ${
            showStreakAlert ? "active" : ""
          }`}
        >
          <div className="streak-alert-confetti">
            <p>{alertMessage}</p>
            {Array.from({ length: 50 }).map((_, index) => (
              <div
                key={index}
                className="confetti piece"
                style={{
                  top: `${Math.random() * 100}%`,
                  left: `${Math.random() * 100}%`,
                  backgroundColor: `hsl(${Math.random() * 360}, 70%, 60%)`,
                  animationDelay: `${Math.random() * 2}s`,
                }}
              />
            ))}
          </div>
        </div>
      )}
      {showModal && (
        <Modal
          correctAnswersCount={correctAnswersCount}
          streakCounter={streakCounter}
          solved={solved}
          showConfetti={true}
          onOpenPreviousQuestion={fetchPreviousDayQuestion}
          category={category} 
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

export default Music;
